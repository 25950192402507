import { provider } from '@/types/auth';

type extendedProvider = provider | 'email';

const providers: extendedProvider[] = ['google', 'facebook', 'apple'];

const saveProviderType = (providerType: string | null) => {
  const foundProvider =
    providerType === null
      ? 'email'
      : providers.find((provider) => providerType.includes(provider));

  if (foundProvider) localStorage.setItem('providerType', foundProvider);
};

export default saveProviderType;
