import { ActionType } from '@/types/auth';

const ACTION_TYPES: ActionType = {
  ACCOUNT_LINKING: 'ACCOUNT_LINKING',
  EMAIL_SIGNUP: 'EMAIL_SIGNUP',
  SOCIAL_SIGNUP: 'SOCIAL_SIGNUP',
  EMAIL_SIGNIN: 'EMAIL_SIGNIN',
  SOCIAL_SIGNIN: 'SOCIAL_SIGNIN',
};

export { ACTION_TYPES };
