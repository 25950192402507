const getGaClientToken = () => {
  const token = document?.cookie
    ?.split('; ')
    ?.find((row) => row.startsWith('_ga='))
    ?.split('.')
    ?.slice(2, 4)
    ?.join('.');

  return token;
};

export default getGaClientToken;
