const CAMERAFI_LIVE_ANDROID = 'camerafi-live-android';
const CAMERAFI_LIVE_IOS = 'camerafi-live-ios';
const CAMERAFI_STUDIO = 'camerafi-studio';
const PLAY_STORE = 'play-store';

export {
  CAMERAFI_LIVE_ANDROID,
  CAMERAFI_LIVE_IOS,
  CAMERAFI_STUDIO,
  PLAY_STORE,
};
