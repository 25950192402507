import { CAMERAFI_STUDIO } from '@constants/services';

const WEB = 'web';

const getLastVisitedService = (service: string) => {
  switch (service) {
    case WEB:
      return CAMERAFI_STUDIO;
    default:
      return service;
  }
};

export default getLastVisitedService;
